<template>
  <div class="h-100">
    <h4 class="fw-bold mb-4">
      Dati Anagrafica Azienda
    </h4>
    <div v-if="!isLoading">
      <ProfileForm
          :user-detail="data"
          :create="create"
          :update="update"
          :user-id="userId"
      />
    </div>
    <div v-else class="h-100 d-flex justify-content-center align-items-center">
      <SpinnerBlue :loading="isLoading" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { details } from "@/api/users.api";
import { Response } from '@/types/Response';
import { UserDetail } from "@/types/User";
import ProfileForm from "@/components/forms/profile/ProfileForm.vue";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";

export default defineComponent({
  name: "Profile",
  mixins: [spinnerIsLoading],
  components: {SpinnerBlue, ProfileForm },
  data() {
    return {
      userId: this.$store.getters.user.id,
      create: false,
      update: false,
      message: '',
      data: {} as UserDetail
    }
  },
  methods: {
    async getUserDetails() {
      this.startLoading();
      const response: Response = await details(this.userId as number);
      const data: UserDetail = response.data;

      this.stopLoading();

      if (!data) return;

      if (data.length === 0) {
        this.create = true;

        return;
      }

      this.data = data;
      this.update = true;

    }
  },
  mounted() {
    this.getUserDetails();
  }
})
</script>

<style scoped>

</style>