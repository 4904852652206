
import { defineComponent, PropType } from "vue";
import { UserDetail } from "@/types/User";
import { createDetails, updateDetails } from "@/api/users.api";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertError from "@/components/alerts/AlertError.vue";
import province from '@/data/province.json';

export default defineComponent({
  name: "ProfileForm",
  components: {AlertError, AlertSuccess},
  props: {
    userDetail: {
      type: Object as PropType<UserDetail>
    },
    create: Boolean,
    update: Boolean,
    userId: Number,
  },
  data() {
    return {
      message: '',
      error: '',
      province: province
    }
  },
  methods: {
    resetMessages() {
      this.message = '';
      this.error = '';
    },
    validateFields() {
      this.resetMessages();

      if (this.userDetail === undefined) return;

      if (!this.userDetail.city) {
        return this.error = 'La città è un campo obbligatorio.';
      }

      if (this.userDetail.cf) {
        const regex = new RegExp('^[A-Z]{6}\\d{2}[A-Z]\\d{2}[A-Z]\\d{3}[A-Z]$')

        if (!regex.test(this.userDetail.cf)) {
          return this.error = 'Il Codice Fiscale non sembra avere un formato corretto.';
        }
      }

      this.updateOrCreate();
    },
    updateOrCreate() {
      this.create ? this.createProfile() : this.updateProfile();
    },
    async createProfile() {
      const response = await createDetails(this.userId as number, this.userDetail as UserDetail);

      if (response.data) {
        this.message = 'Profilo creato con successo.';
        window.location.reload();
      }
    },
    async updateProfile() {
      const response = await updateDetails(this.userId as number, this.userDetail as UserDetail);

      if (response.data) {
        this.message = 'Profilo aggiornato con successo.';
        this.clearMessage();
      }
    },
    clearMessage() {
      if (!this.message) return;

      setTimeout(() => {
        this.message = '';
      }, 3000);
    },
    selectedProvinceItem() {
      //@ts-ignore
      if (!this.userDetail.province) {
        //@ts-ignore
        this.$refs.selectedItem.selectedIndex = 0;
      }
    }
  },
  mounted() {
    this.selectedProvinceItem();
  },
  updated() {
    this.selectedProvinceItem();
  }
})
