<template>
  <div class="px-4">
    <AlertSuccess :message="message" />
    <AlertError :error="error" />
    <form class="row g-3">
      <div class="col-md-6">
        <label for="ragione-sociale" class="form-label">Ragione Sociale</label>
        <input
            type="email"
            class="form-control"
            id="ragione-sociale"
            v-model="userDetail.business_name"
        >
      </div>
      <div class="col-md-6">
        <label for="cf" class="form-label">CF</label>
        <input type="text" class="form-control" id="cf" v-model="userDetail.cf">
      </div>
      <div class="col-12">
        <label for="piva" class="form-label">P.IVA</label>
        <input type="text" class="form-control" id="piva" v-model="userDetail.p_iva"/>
      </div>
      <div class="col-12">
        <label for="address" class="form-label">Indirizzo</label>
        <input
            type="text"
            class="form-control"
            id="address"
            placeholder="Via Roma 22"
            v-model="userDetail.address"
        />
      </div>
      <div class="col-md-6">
        <label for="inputCity" class="form-label">Città</label>
        <input
            type="text"
            class="form-control"
            id="inputCity"
            placeholder="Roma"
            v-model="userDetail.city"
        >
      </div>
      <div class="col-md-4">
        <label for="inputState" class="form-label">Provincia</label>
        <select ref="selectedItem" id="inputState" class="form-select" v-model="userDetail.province">
          <option selected>Scegli...</option>
          <option v-for="provincia in province" :key="provincia.sigla">
            {{ provincia.sigla }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <label for="inputZip" class="form-label">CAP</label>
        <input
            type="text"
            class="form-control"
            id="inputZip"
            placeholder="80000"
            v-model="userDetail.cap"
        />
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
            type="button"
            class="btn bg-blue text-uppercase mt-4 px-5"
            @click="validateFields()"
        >
          Salva
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { UserDetail } from "@/types/User";
import { createDetails, updateDetails } from "@/api/users.api";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertError from "@/components/alerts/AlertError.vue";
import province from '@/data/province.json';

export default defineComponent({
  name: "ProfileForm",
  components: {AlertError, AlertSuccess},
  props: {
    userDetail: {
      type: Object as PropType<UserDetail>
    },
    create: Boolean,
    update: Boolean,
    userId: Number,
  },
  data() {
    return {
      message: '',
      error: '',
      province: province
    }
  },
  methods: {
    resetMessages() {
      this.message = '';
      this.error = '';
    },
    validateFields() {
      this.resetMessages();

      if (this.userDetail === undefined) return;

      if (!this.userDetail.city) {
        return this.error = 'La città è un campo obbligatorio.';
      }

      if (this.userDetail.cf) {
        const regex = new RegExp('^[A-Z]{6}\\d{2}[A-Z]\\d{2}[A-Z]\\d{3}[A-Z]$')

        if (!regex.test(this.userDetail.cf)) {
          return this.error = 'Il Codice Fiscale non sembra avere un formato corretto.';
        }
      }

      this.updateOrCreate();
    },
    updateOrCreate() {
      this.create ? this.createProfile() : this.updateProfile();
    },
    async createProfile() {
      const response = await createDetails(this.userId as number, this.userDetail as UserDetail);

      if (response.data) {
        this.message = 'Profilo creato con successo.';
        window.location.reload();
      }
    },
    async updateProfile() {
      const response = await updateDetails(this.userId as number, this.userDetail as UserDetail);

      if (response.data) {
        this.message = 'Profilo aggiornato con successo.';
        this.clearMessage();
      }
    },
    clearMessage() {
      if (!this.message) return;

      setTimeout(() => {
        this.message = '';
      }, 3000);
    },
    selectedProvinceItem() {
      //@ts-ignore
      if (!this.userDetail.province) {
        //@ts-ignore
        this.$refs.selectedItem.selectedIndex = 0;
      }
    }
  },
  mounted() {
    this.selectedProvinceItem();
  },
  updated() {
    this.selectedProvinceItem();
  }
})
</script>

<style scoped>
  button,
  .form-select,
  .form-control {
    border-radius: 22px;
  }

  label.form-label {
    color: #173759;
    font-weight: 600;
    margin-left: 10px;
    text-transform: uppercase;
  }
</style>